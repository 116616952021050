<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="编码">
              <a-input
                v-decorator="[
                  'code',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="描述"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="['remarks']"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="saveLoading"
              >保存</a-button
            >
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { fetchDetail, edit } from "@/api/setting/module";

export default {
  name: "editModule",

  data() {
    return {
      form: this.$form.createForm(this),
      uuid: null,
      saveLoading: false,
    };
  },

  activated() {
    this.getData();
  },

  methods: {
    getData() {
      const { query } = this.$route;
      const { uuid } = query || {};

      if (uuid && uuid !== this.uuid) {
        this.uuid = uuid;
        fetchDetail({
          uuid,
        })
          .then((res) => {
            this.form.setFieldsValue({
              code: res.code,
              name: res.name,
              remarks: res.remarks,
            });
          })
          .finally(() => {});
      }
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true;
          edit({
            uuid: this.uuid,
            ...values,
          })
            .then(() => {})
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
